import React, { useState, useEffect } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import Testimonial from "../../components/Testimonial/testimonial";
import "./home.css";
import ShowcaseCard from "../../components/Showcase Card/showcaseCard";
import ImageViewer from "react-simple-image-viewer";
import Banner from "../../components/Banner/banner";
import ProductCard from "../../components/ProductCard/productCard";
import {
  query,
  collection,
  orderBy,
  getDocs,
  where,
} from "firebase/firestore/lite";
import { auth, db } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { message } from "antd";

export default function Home(props) {
  const [productsArray, setProductArray] = useState([]);
  const [maleBagsArray, setMaleBagsArray] = useState([]);
  const getAllFemalBags = async () => {
    let femalBagsArray = [];
    try {
      let products = await getDocs(
        query(
          collection(db, "products"),

          where("category", "==", "Bags for female"),
          orderBy("createdAt", "desc")
        )
      );

      for (var snap of products.docs) {
        let product = snap.data();
        product.key = snap.id;
        femalBagsArray.push(product);
      }

      if (femalBagsArray.length === products.docs.length) {
        //setFemalBagsArray(femalBagsArray);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const getAllMaleBags = async () => {
    let maleBagsArray = [];
    try {
      let products = await getDocs(
        query(
          collection(db, "products"),

          where("category", "==", "Bags for male"),
          orderBy("createdAt", "desc")
        )
      );

      for (var snap of products.docs) {
        let product = snap.data();
        product.key = snap.id;
        maleBagsArray.push(product);
      }

      if (maleBagsArray.length === products.docs.length) {
        setMaleBagsArray(maleBagsArray);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    // getAllFemalBags();
    // getAllMaleBags();
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          props.history.push("/home");
        } catch (err) {
          message.error(err.message);
        }
      } else {
        props.history.push("/");
      }
    });
  }, []);

  const productList = [
    "Bags for female",
    "Bags for male",
    "Shoes for female",
    "Shoes for male",
    "Sunglasses",
    "Jewelry",
    "Watch",
    "Clothes for female",
    "Clothes for male",
    "Stuff for kid and toddler",
    "wallet",
    "Scarf",
    "Swimsuit",
  ];
  const [selectedFilter, setFilter] = useState("Bags for female");

  const getAllProducts = async () => {
    let productsList = [];
    try {
      let products = await getDocs(
        query(
          collection(db, "products"),

          where("category", "==", selectedFilter),
          orderBy("createdAt", "desc")
        )
      );

      for (var snap of products.docs) {
        let product = snap.data();
        product.key = snap.id;

        productsList.push(product);
      }

      if (productsList.length === products.docs.length) {
        setProductArray(productsList);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [selectedFilter]);
  const selectProductList = (item) => {
    setProductArray([]);
    setFilter(item);
  };

  return (
    <div>
      <Banner
        // title='Contact Us'
        bgimage={require("../../Assets/banner1.jpg")}
      />
      <Header props={props} />

      <div className="container">
        <div className="work-section">
          <div className="product-title-list">
            {productList.map((item, index) => (
              <span
                style={{
                  color: selectedFilter === item ? "black" : "gray",
                  borderBottom:
                    selectedFilter === item ? "2px solid black" : "transparent",
                }}
                onClick={() => selectProductList(item)}
                key={index}
              >
                {item}
              </span>
            ))}
          </div>
          <div className="flex-row">
            {productsArray.length ? (
              productsArray.map((val, i) => <ProductCard val={val} key={i} />)
            ) : (
              <h1
                style={{
                  width: "100%",
                  height: 200,
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 40,
                }}
              >
                No Products
              </h1>
            )}
          </div>
          {/* {maleBagsArray.length ? (
            <>
              <h1>Bags for Male</h1>
              <div className="flex-row">
                {maleBagsArray.length ? (
                  maleBagsArray.map((val, i) => (
                    <ProductCard val={val} key={i} />
                  ))
                ) : (
                  <h1
                    style={{
                      width: "100%",
                      height: 200,
                      backgroundColor: "#f4f4f4",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 40,
                    }}
                  >
                    No Products
                  </h1>
                )}
              </div>
            </>
          ) : null} */}
        </div>
      </div>

      <Footer props={props} />
    </div>
  );
}
