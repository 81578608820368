import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getFirestore as getFirestoreAuth } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtyoZYc8jjmdb1GxWE6L6MG3B53VcuZUs",
  authDomain: "shopwithmia-e453a.firebaseapp.com",
  projectId: "shopwithmia-e453a",
  storageBucket: "shopwithmia-e453a.appspot.com",
  messagingSenderId: "736721549911",
  appId: "1:736721549911:web:8d719821f52f3e4e2bc112",
  measurementId: "G-23385FTKHQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// export const auth = firebase.auth();
export const db = getFirestore(app);
export const dbAuth = getFirestoreAuth(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
