import React, { useEffect, useState } from "react";
import "./styles.css";
import { Button, message } from "antd";
import Header from "../../components/Header/header";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

import { doc, getDoc } from "firebase/firestore";

import { auth, dbAuth } from "../../config/firebase";
import Loader from "../../components/Loader/loader";

export default function Login(props) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    user: {},
  });

  const onHandlerChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const onSubmit = async () => {
    const { email, password } = state;
    if (email === "") {
      return message.error("Email is required");
    }
    if (password === "") {
      return message.error("Password is required");
    }

    const credential = {
      email: email.toLowerCase().trim(),
      password: password.trim(),
    };

    setLoading(true);
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        credential.email,
        credential.password
      );

      let docRef = doc(dbAuth, "users", user.user.uid);

      let userData = await getDoc(docRef);

      localStorage.setItem("user", JSON.stringify(userData.data()));
      props.history.push("/home");
      setLoading(false);
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          props.history.push("/home");
        } catch (err) {
          message.error(err.message);
        }
      } else {
      }
    });
  }, []);
  return (
    <>
      {loading ? <Loader /> : null}
      {/* <Header user={state.user} login={true} props={props} /> */}
      <div className="login-container">
        <h1>Login</h1>
        <div className="formGroup">
          <h4>Email</h4>
          <input
            type="email"
            onChange={onHandlerChange}
            id="email"
            value={state.email}
            className="input"
            placeholder="type your email here..."
          />
        </div>
        <div className="formGroup">
          <h4>Password</h4>
          <input
            onChange={onHandlerChange}
            id="password"
            className="input"
            type="password"
            value={state.password}
            placeholder="type your password here..."
          />
        </div>
        <div className="formGroup">
          <Button type="primary" onClick={onSubmit}>
            Submit
          </Button>
        </div>
        <div className="formGroup">
          <p>
            don't have an account ?{" "}
            <span
              onClick={() => props.history.push("/signup")}
              className="link"
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
