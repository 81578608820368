import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";

import "./about.css";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

// Import styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Banner from "../../components/Banner/banner";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import { message } from "antd";

export default function AboutUs(props) {
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          props.history.push("/about");
        } catch (err) {
          message.error(err.message);
        }
      } else {
        props.history.push("/");
      }
    });
  }, []);
  // creating new plugin instance

  // pdf file onChange state

  return (
    <div>
      <Banner
        // title='Contact Us'
        bgimage={require("../../Assets/banner1.jpg")}
      />
      <Header props={props} />

      <div className="container">
        <div className="contact-middle-section">
          <h1>About Me</h1>
          <p>
            Located in Miramar FL. We provide high quality bags. Shoes. Jewelry.
            We have show room for you.
          </p>
        </div>
      </div>
      <Footer props={props} />
    </div>
  );
}
