import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./screens/home/home";
import About from "./screens/About/about";
import ContactUs from "./screens/Contact/contact";
import Products from "./screens/Products/products";
import Login from "./screens/Auth/Login";
import Signup from "./screens/Auth/Signup";
import { ConfigProvider, Drawer } from "antd";

function App() {
  return (
    <>
      <ConfigProvider>
        <Router>
          <Route exact path="/home" component={Home} />
          <Route exact path="/" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={ContactUs} />
          {/* <Route exact path="/products" component={Products} /> */}
        </Router>
      </ConfigProvider>
    </>
  );
}

export default App;
