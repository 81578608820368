import React, { useEffect, useState } from "react";
import "./header.css";
import { Button, Drawer, Radio, Space, message } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

import { auth, dbAuth } from "../../config/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export default function Header({ props, login }) {
  const onNavigate = (route) => {
    props.history.push(route);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const [openDrawer, setDrawer] = useState(false);
  const showDrawer = () => {
    setDrawer(true);
  };
  const onClose = () => {
    setDrawer(false);
  };

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.setItem("user", null);
      props.history.push("/");
    } catch (err) {
      message.error(err.message);
    }
  };

  console.log(props);
  return (
    <>
      {!login ? (
        <div className="drawer-for-mobile">
          <Drawer
            title="Shop With Mia"
            closable={true}
            onClose={onClose}
            open={openDrawer}
            placement="left"
          >
            {user ? (
              <p
                className="nav-link"
                style={{
                  color: "crimson",
                }}
              >
                {user.email}
              </p>
            ) : null}
            <p
              className="nav-link"
              onClick={() => onNavigate("/home")}
              style={{
                color: props.location.pathname === "/home" ? "crimson" : null,
              }}
            >
              HOME
            </p>
            {/* <p
              className="nav-link"
              onClick={() => onNavigate("/products")}
              style={{
                color:
                  props.location.pathname === "/products" ? "crimson" : null,
              }}
            >
              PRODUCTS
            </p> */}

            <p
              className="nav-link"
              onClick={() => onNavigate("/about")}
              style={{
                color: props.location.pathname === "/about" ? "crimson" : null,
              }}
            >
              ABOUT US
            </p>
            <p
              className="nav-link"
              onClick={() => onNavigate("/contact")}
              style={{
                color:
                  props.location.pathname === "/contact" ? "crimson" : null,
              }}
            >
              CONTACT US
            </p>
            <p className="nav-link" onClick={logout}>
              LOGOUT
            </p>
          </Drawer>
        </div>
      ) : null}
      <div className="header-container">
        <div className="logo-container"></div>

        <h4>SHOPWITHMIA</h4>

        {!login ? (
          <>
            <div className="menu-icon">
              <UnorderedListOutlined
                onClick={showDrawer}
                style={{ fontSize: 30, color: "white" }}
              />
            </div>
            <div className="header-nav">
              {user ? (
                <div
                  className="nav-link"
                  style={{
                    color: "crimson",
                  }}
                >
                  {user.email}
                </div>
              ) : null}
              <div
                className="nav-link"
                onClick={() => onNavigate("/home")}
                style={{
                  color: props.location.pathname === "/home" ? "crimson" : null,
                }}
              >
                HOME
              </div>
              {/* <div
                className="nav-link"
                onClick={() => onNavigate("/products")}
                style={{
                  color:
                    props.location.pathname === "/products" ? "crimson" : null,
                }}
              >
                PRODUCTS
              </div> */}

              <div
                className="nav-link"
                onClick={() => onNavigate("/about")}
                style={{
                  color:
                    props.location.pathname === "/about" ? "crimson" : null,
                }}
              >
                ABOUT US
              </div>
              <div
                className="nav-link"
                onClick={() => onNavigate("/contact")}
                style={{
                  color:
                    props.location.pathname === "/contact" ? "crimson" : null,
                }}
              >
                CONTACT US
              </div>
              <div className="nav-link" onClick={logout}>
                LOGOUT
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
