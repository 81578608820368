import React, { useState } from "react";
import "./banner.css";
export default function Banner({ title, tagline, bgimage, myStyle, home }) {
  return (
    <div
      className="banner-container"
      style={{ backgroundImage: `url(${bgimage})`, ...myStyle }}
    >
      <div className="banner-overlay">
        {/* <h1>{title}</h1> */}
        <div>
          <h2>LOVE&LIFE&PEACE</h2>
          <p>Designer Handbags</p>
          <span className="shop-btn">SHOP</span>
        </div>
        {/* {home ? (
          <div className='banner-detail'>
            <div> Eighth Avenue 385, New York </div>
            <div>Tel: +7(111)8765423</div>
          </div>
        ) : null} */}
        {/* {data} */}
      </div>
    </div>
  );
}
