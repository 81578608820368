import React, { useState } from "react";

import "./productCard.css";

export default function ProductCard({ val }) {
  return (
    <div className="product-card-container">
      <div className="product-card-img-container">
        <img src={val.image} />
      </div>

      {/* <div className="product-card-detail">
        <div className="product-card-title">{val.title}</div>
        <div className="product-card-price">${val.price}</div>
      </div> */}
    </div>
  );
}
