import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/banner";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import "./contact.css";
import { Icon } from "react-icons-kit";
import { location } from "react-icons-kit/icomoon/location";
import { phone } from "react-icons-kit/icomoon/phone";
import { mail } from "react-icons-kit/icomoon/mail";
import { instagram } from "react-icons-kit/icomoon/instagram";
import { facebook } from "react-icons-kit/icomoon/facebook";
import { twitter } from "react-icons-kit/feather/twitter";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import { message } from "antd";

export default function ContactUs(props) {
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          props.history.push("/contact");
        } catch (err) {
          message.error(err.message);
        }
      } else {
        props.history.push("/");
      }
    });
  }, []);
  return (
    <div>
      <Header props={props} />
      <Banner
        // title='Contact Us'
        bgimage={require("../../Assets/banner1.jpg")}
      />
      <div className="container"></div>

      <div className="contact-middle-section">
        <h2>Contact</h2>
        <div className="contact-box-container">
          <div className="contact-detail-boxes">
            <Icon icon={location} className="contact-box-icon" size={40} />
            <div className="contact-detail-box-text">Located in Miramar FL</div>
          </div>
          <div className="contact-detail-boxes">
            <Icon icon={phone} className="contact-box-icon" size={40} />
            <div className="contact-detail-box-text">3054918102</div>
          </div>
          <div className="contact-detail-boxes">
            <Icon icon={mail} className="contact-box-icon" size={40} />
            <div className="contact-detail-box-text">Mia.l13@icloud.com</div>
          </div>
        </div>
        <div className="contact-social-icon-container">
          {/* <div
            className="contact-social-box"
            onClick={() =>
              window
                .open
                // 'https://www.facebook.com/Touched_By_Niq-226566501258107/',
                // '_blank'
                ()
            }
          >
            <Icon icon={facebook} size={20} />
          </div> */}
          <div
            className="contact-social-box"
            onClick={() =>
              window.open(
                "https://www.instagram.com/mia.collection13?igshid=YmMyMTA2M2Y%3D",
                "_blank"
              )
            }
          >
            <Icon icon={instagram} size={20} />
          </div>
        </div>
      </div>
      <Footer props={props} />
    </div>
  );
}
