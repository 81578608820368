import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./style.css";
export default function Loader() {
  return (
    <div style={{ pointerEvents: "none" }} className="loader-container">
      <LoadingOutlined style={{ fontSize: 20, color: "red", zIndex: 1000 }} />
    </div>
  );
}
